import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'
import { Link } from 'gatsby'
import { CenteredOnMobile } from '../Hero/styles'

export const Section = styled.div`
  position: relative;
`

export const JumbotronImageContainer = styled.div`
  margin-top: 0px;
  > div:first-child {
    display: block;
  }
  > div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin-top: -100px;
    > div:first-child {
      display: none;
    }
    > div:nth-child(2) {
      display: block;
    }
  }
  @media (max-width: 600px) {
    margin-top: -50px;
  }
  @media (max-width: 440px) {
    margin-top: 0px;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 5%;
  width: 560px;
  @media (max-width: 1100px) {
    width: 500px;
  }
  @media (max-width: 1400px) {
    width: 450px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    width: 400px;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const Headline = styled.p`
  color: black;
  font-family: 'FilsonPro';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.288px;
  margin-bottom: 10px;
`
export const HeadlineMobile = styled.p`
  display: none;
  text-align: center;
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 20px;
    > div {
      display: inline-block;
    }
  }
`

export const Title = styled.h1`
  color: black;
  font-family: 'FilsonPro';
  font-size: 72px;
  font-style: normal;
  line-height: 111.3%;
  letter-spacing: -0.864px;
  font-weight: 700;
  margin: 24px 0px 16px;
  width: 100%;
  span {
    font-weight: 425;
  }
  @media (max-width: 1400px) {
    font-size: 56px;
    margin: 16px 0px 8px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 48px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 42px;
  }
`
export const MobileTitle = styled.h1`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    color: black;
    font-family: 'FilsonPro';
    font-weight: 700;
    width: 100%;
    span {
      font-weight: 425;
    }
    display: block;
    text-align: center;
    font-size: 38px;
    position: absolute;
    top: 100px;
    line-height: 111.3%;
    letter-spacing: -0.456px;
    padding: 40px 80px;
    margin: 0px auto;
  }
  @media (max-width: 600px) {
    top: 80px;
    width: 360px;
    padding: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 440px) {
    font-size: 36px;
    top: 30px;
    max-width: 90%;
  }
  @media (max-width: 350px) {
    font-size: 32px;
  }
`

export const Text = styled.p`
  color: black;
  font-family: 'FilsonProBook' !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140% !important;
  letter-spacing: -0.24px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 8px 0px;
    font-size: 20px !important;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px !important;
  }
`
export const TextMobile = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.192px;
  @media (max-width: 380px) {
    font-size: 15px;
  }
`

export const Intro = styled.span`
  font-family: 'FilsonPro';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.484px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    display: block;
  }
`
export const MobileContentContainer = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 90%;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 40px;
  }
`
export const IntroMobile = styled.h1`
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px auto 8px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.528px;
    width: 270px;
    max-width: 100%;
  }
`
export const CtaContainer = styled.div`
  display: flex;
`

export const CenteredMobile = styled(CenteredOnMobile)`
  position: relative;
  margin-top: 30px;
  @media (max-width: 350px) {
    width: 100%;
  }
`
const linkStyle = css`
  font-family: 'FilsonPro';
  background-color: ${(props) => props.theme.coral};
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.08px;
  }
`
export const BuyNowLink = styled(Link)`
  ${linkStyle}
  color: ${(props) => (props.ctacolor ? 'black' : 'white')};
  background-color: ${(props) => (props.ctacolor ? props.ctacolor : props.theme.coral)};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.ctacolor || props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.ctacolor || props.theme.coral)}`};
  }
  @media (max-width: 1160px) {
    padding: ${(props) => (props.isCalendarNY ? '15px 15px' : '15px 30px')};
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
    padding: 15px 30px;
  }
  @media (max-width: 350px) {
    display: inline;
    position: relative;
    z-index: 2;
  }
`
