import { BUY_LINK, CAL_BUY_LINK, FRAME_2_BUY_LINK } from '../../utils/constants'

export const PRODUCT_DETAILS = [
  {
    name: 'Frame 2',
    heading: 'Change the way you share memories.',
    subheading: `Our bestselling smart photo frame just got even better. And with our new Snap Frames, you don't have to choose a color - you can have them all.`,
    link: `${FRAME_2_BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Homepage CTA',
  },
  {
    name: 'Calendar',
    heading: 'The All-In-One Smart Family Calendar',
    subheading:
      'Get the whole family organized with chore charts, dinner planning, and customizable lists — all on one display the whole family can use.',
    link: `${CAL_BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '6863425175604',
    analyticsEvent: 'Clicked Buy Now Calendar Hero on Homepage CTA',
  },
  {
    name: 'Frame',
    heading: 'The perfect gift',
    subheading: 'Meet the photo frame you can send photos to, and they appear in seconds!',
    link: `${BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame Hero on Homepage CTA',
  },
]
